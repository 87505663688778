import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange } from '@angular/material/select';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { TilledParagraphP4Component } from 'app/shared/tilled-text';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isEmail } from 'app/shared/validators/email.validator';
import { isPhoneNumber } from 'app/shared/validators/phone.validator';
import { isValidUrl } from 'app/shared/validators/url.validator';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { OnboardingAddress, OnboardingApplication } from '../../../../../projects/tilled-api-client/src';
import { AutocompleteComponent } from '../../autocomplete/autocomplete.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'business-contact-step',
  templateUrl: './business-contact-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    AutocompleteComponent,
    TilledSelectComponent,
    MatCheckboxModule,
    MatIconModule,
    AsyncPipe,
    TilledParagraphP4Component,
  ],
})
export class BusinessContactStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  public businessContactForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public selectorTypes: SelectorTypes = new SelectorTypes();
  public phoneCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.CountryToPhoneCode).map(
    ([label, value]) => ({ label, value }),
  );
  public selectedPhoneCode: string;
  public supportPhone: string = '';
  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.stateAndProvinceMap).map(
    ([value, label]) => ({ label, value }),
  );
  private subscriptions: Subscription[] = [];
  private stateAndProvinceMap = SelectorTypes.stateAndProvinceMap;

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.businessContactForm = this._formBuilder.group({
      phone: new FormControl<string | null>(this.merchantApp?.legal_entity?.support_phone || null, [
        isPhoneNumber('US'),
      ]),
      phoneCode: new FormControl<string | null>(this.selectedPhoneCode || null),
      email: new FormControl<string | null>(this.merchantApp?.legal_entity?.support_email || null, [isEmail()]),
      website: new FormControl<string | null>(this.merchantApp?.legal_entity?.website || null, [
        Validators.pattern(/(?:\w+\.)+\w+(?:\/\S*)?/i),
        isValidUrl({ require_protocol: false }),
      ]),
      street: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.street || null),
      street2: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.street2 || null),
      city: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.city || null),
      state: new FormControl<string | null>(
        SelectorTypes.stateAndProvinceMap.get(this.merchantApp?.legal_entity?.address?.state) || null,
      ),
      zip: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.postal_code || null),
      country: new FormControl<string | null>(this.merchantApp?.legal_entity?.address?.country || null),
    });

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.merchantApp?.legal_entity && !this.merchantApp.legal_entity.address) {
      this.merchantApp.legal_entity.address = {} as OnboardingAddress;
    }

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessContactForm.disable();
          } else {
            this.businessContactForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
    this.selectedPhoneCode = this.selectedPhoneCode ? this.selectedPhoneCode : this.phoneCodeMap[0].value;
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onPhoneCodeChanged(event: MatSelectChange): void {
    this.selectedPhoneCode = event.value;
  }

  public setBusinessAddress(address: OnboardingAddress): void {
    this.businessContactForm.patchValue({
      street: address.street,
      city: address.city,
      state: address.state ? [...this.stateAndProvinceMap].find(([key, val]) => key === address.state)[0] : null,
      zip: address.postal_code,
    });
    this.businessContactForm.markAsTouched();
  }

  onBackClicked(event: string): void {
    this._merchantAppService.updateCurrentStep(this.stepNumber - 1);
  }

  onContinueClicked(accountId?: string): void {
    this.businessContactForm.markAllAsTouched();
    if (this.businessContactForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    // eslint-disable-next-line guard-for-in
    for (const field in this.businessContactForm.controls) {
      const control = this.businessContactForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }
    // if the phone number is not prefixed with a country code, prefix it with the selected country code (US by default)
    if (this.businessContactForm.value.phone && !this.businessContactForm.value.phone.startsWith('+')) {
      this.businessContactForm.patchValue({
        phone: `${this.selectedPhoneCode}${this.businessContactForm.value.phone}`,
      });
    }
    this.merchantApp.legal_entity.support_phone = this.businessContactForm.value.phone;
    this.merchantApp.legal_entity.support_email = this.businessContactForm.value.email;
    // prefix the website with `https://` if it doesn't already have it (New URL fields shows a `https://` prefix)
    if (
      this.businessContactForm.value.website &&
      !this.businessContactForm.value.website.startsWith('https://' || 'http://')
    ) {
      this.businessContactForm.patchValue({
        website: `https://${this.businessContactForm.value.website}`,
      });
    }
    this.merchantApp.legal_entity.website = this.businessContactForm.value.website;
    this.merchantApp.legal_entity.address.street = this.businessContactForm.value.street;
    this.merchantApp.legal_entity.address.street2 = this.businessContactForm.value.street2;
    this.merchantApp.legal_entity.address.city = this.businessContactForm.value.city;
    this.merchantApp.legal_entity.address.state = this.businessContactForm.value.state
      ? [...this.stateAndProvinceMap].find(([key, val]) => key === this.businessContactForm.value.state)[0]
      : null;
    this.merchantApp.legal_entity.address.postal_code = this.businessContactForm.value.zip;
    this.merchantApp.legal_entity.address.country = SelectorTypes.getCountryFromState(
      this.merchantApp.legal_entity.address.state,
    );

    this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber + 1, accountId);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  private isAppUnsaved(): boolean {
    const state = this.businessContactForm.value.state
      ? [...this.stateAndProvinceMap].find(([key, val]) => key === this.businessContactForm.value.state)[0]
      : null;

    return !(
      _compareTwoStrings(this.merchantApp.legal_entity?.support_phone, this.businessContactForm.value.phone) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.support_email, this.businessContactForm.value.email) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.website, this.businessContactForm.value.website) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.street, this.businessContactForm.value.street) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.street2, this.businessContactForm.value.street2) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.city, this.businessContactForm.value.city) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.state, state) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.postal_code, this.businessContactForm.value.zip) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.address?.country, SelectorTypes.getCountryFromState(state))
    );
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;

    const state = ble?.address?.state
      ? [...this.stateAndProvinceMap].find(([key, val]) => key === ble?.address?.state)[0]
      : null;
    this.businessContactForm.controls['phone'].setValue(ble?.support_phone);
    this.businessContactForm.controls['email'].setValue(ble?.support_email);
    this.businessContactForm.controls['website'].setValue(ble?.website);
    this.businessContactForm.controls['street'].setValue(ble?.address?.street);
    this.businessContactForm.controls['street2'].setValue(ble?.address?.street2);
    this.businessContactForm.controls['city'].setValue(ble?.address?.city);
    this.businessContactForm.controls['state'].setValue(state);
    this.businessContactForm.controls['zip'].setValue(ble?.address?.postal_code);
    this.businessContactForm.controls['country'].setValue(SelectorTypes.getCountryFromState(ble?.address?.state));
  }
}
