import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { TransactionFeeTypePipe } from 'app/core/pipes/transaction-fee-type-pipe';
import { CardChargeFeeTemplate, PricingTemplate } from '../../../../../projects/tilled-api-client/src';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'card-pricing',
  templateUrl: './card-pricing-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledParagraphP3Component,
    MatDividerModule,
    MinorUnitsToCurrencyPipe,
    CommonModule,
    TransactionFeeTypePipe,
  ],
})
export class CardPricingCardComponent implements OnInit {
  @Input() showCard?: boolean = true;
  @Input() label?: string = 'PRICING DETAILS';
  @Input() currency?: PricingTemplate.CurrencyEnum = PricingTemplate.CurrencyEnum.USD;
  @Input() feeType?: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  @Input() visaRate?: number = 0;
  @Input() amexRate?: number = 0;
  @Input() transactionFee?: number = 0;
  @Input() chargebackFee?: number = 0;
  @Input() retrievalFee?: number = 0;
  @Input() reversalFee?: number = 0;
  @Input() accountMonthlyFee?: number = 0;
  @Input() accountMonthlyMinimumFee?: number = 0;
  @Input() passThroughFees?: boolean = false;

  constructor() {}

  ngOnInit() {}
}
