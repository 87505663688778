/* eslint-disable @typescript-eslint/member-ordering */
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule, KeyValue } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AuthService } from 'app/core/services/auth.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { FilesAppService } from 'app/core/services/files.app.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { AccountDocumentsUploadComponent } from 'app/shared/account-documents/upload/account-documents-upload.component';
import { FileUploadComponent } from 'app/shared/file-upload/file-upload.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import {
  TilledHeadingH3Component,
  TilledHeadingH4Component,
  TilledParagraphP1Component,
  TilledParagraphP2Component,
  TilledParagraphP3Component,
} from 'app/shared/tilled-text';
import { isValidYear } from 'app/shared/validators/dob.validator';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  combineLatest,
  filter,
  map,
  takeUntil,
} from 'rxjs';
import {
  DocumentDto,
  InternalAccount,
  InternalDocument,
  ModelFile,
  OnboardingApplication,
  PAArticlesOfIncorporation,
  PABusinessLicense,
  PatriotActDetails,
  SubmitDocumentRequestParams,
} from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';

@Component({
  selector: 'business-documents-step',
  templateUrl: './business-documents-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    AsyncPipe,
    TilledHeadingH4Component,
    TilledHeadingH3Component,
    TilledParagraphP3Component,
    TilledParagraphP2Component,
    TilledParagraphP1Component,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    AccountDocumentsUploadComponent,
    FileUploadComponent,
  ],
})
export class BusinessDocumentsStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('documentsComponentAOI') documentsComponentAOI: AccountDocumentsUploadComponent;
  @ViewChild('documentsComponentBL') documentsComponentBL: AccountDocumentsUploadComponent;

  public businessDocumentsForm: FormGroup;
  public merchantApp: OnboardingApplication;
  private subscriptions: Subscription[] = [];

  public stateCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.statesMap).map(
    ([value, label]) => ({ label, value }),
  );

  public fileTypes = [ModelFile.TypeEnum.PDF, ModelFile.TypeEnum.PNG, ModelFile.TypeEnum.JPG];
  public description501c3 = ['501c3'];
  public filePurpose = ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION;
  private pendingFiles: number = 0;

  private allFiles$: Observable<ModelFile[]>;
  private subscription: Subscription;
  private _doc501c3$ = new BehaviorSubject<DocumentDto>(null);
  public doc501c3$ = this._doc501c3$.asObservable();
  private _file501c3$ = new BehaviorSubject<ModelFile[]>(null);
  public file501c3$ = this._file501c3$.asObservable();
  public isCharityOrReligiousOrg: boolean;
  public hasTsysProvider: boolean;

  private _showArticlesOfIncorp$ = new BehaviorSubject<boolean>(false);
  public showArticlesOfIncorp$ = this._showArticlesOfIncorp$.asObservable();
  private _showBusinessLicense$ = new BehaviorSubject<boolean>(false);
  public showBusinessLicense$ = this._showBusinessLicense$.asObservable();

  public account: InternalAccount;
  private _account$ = new Subject<InternalAccount>();
  public account$ = this._account$.asObservable();

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _documentsService: DocumentsAppService,
    private _authService: AuthService,
    private _accountAppService: AccountAppService,
    private _filesAppService: FilesAppService,
  ) {
    super();
    this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
      this.hasTsysProvider = hasTsysProvider;
    });
  }

  ngOnInit(): void {
    this.allFiles$ = this._filesAppService.filesAll$;
    this.subscriptions.push(
      combineLatest([
        this._authService.account$,
        this._accountAppService.connectedAccount$.pipe(
          takeUntil(this._unsubscribeAll),
          // if connectedAccount$ never emits, use EMPTY observable
          catchError(() => EMPTY),
        ),
      ])
        .pipe(
          takeUntil(this._unsubscribeAll),
          map(([account, connectedAccount]) => connectedAccount || account),
          filter((account) => !!account),
        )
        .subscribe((account: InternalAccount) => {
          // Update this.account with the latest non-null value
          this.account = account;
          if (this.account) {
            this._account$.next(this.account);
          }
        }),
    );
    this._documentsService.getAllDocuments(this.account.id, null);
    this._filesAppService.listAllFiles(this.account.id, [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION]);
    this.subscription = combineLatest([this._documentsService.documentsAll$, this.allFiles$]).subscribe(
      ([docs, files]) => {
        if (docs) {
          docs = docs.filter(
            (doc) =>
              doc.status === InternalDocument.StatusEnum.SUBMITTED ||
              doc.status === InternalDocument.StatusEnum.VERIFIED,
          );
          const doc501c3 = docs.find((doc) => doc.id === this.merchantApp?.legal_entity?.charity_document?.document_id);
          if (doc501c3) {
            this._doc501c3$.next(doc501c3);
            const file501c3 = files?.find((file) => file?.id === doc501c3.file_ids[0]);
            this._file501c3$.next([file501c3]);
          }
        }
      },
    );

    this.businessDocumentsForm = this._formBuilder.group({
      businessDocsCheck: new FormControl<boolean>(
        this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation
          ? true
          : this.merchantApp?.legal_entity?.patriot_act_details?.business_license
            ? false
            : null,
      ),
      articlesOfIncorpIssuedAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.issued_at
          ? moment(this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.issued_at).format(
              'MM/DD/YYYY',
            )
          : null,
        [isValidYear(1600)],
      ),
      articlesOfIncorpState: new FormControl<string>(
        SelectorTypes.statesMap.get(
          this.merchantApp?.legal_entity?.patriot_act_details?.articles_of_incorporation.state,
        ) || null,
      ),
      businessLicenseIssuedAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.business_license.issued_at
          ? moment(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.issued_at).format('MM/DD/YYYY')
          : null,
        [isValidYear(1600)],
      ),
      businessLicenseExpiresAt: new FormControl<string | null>(
        this.merchantApp?.legal_entity?.patriot_act_details?.business_license.expires_at
          ? moment(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.expires_at).format(
              'MM/DD/YYYY',
            )
          : null,
        [isValidYear(1600)],
      ),
      businessLicenseState: new FormControl<string>(
        SelectorTypes.statesMap.get(this.merchantApp?.legal_entity?.patriot_act_details?.business_license.state) ||
          null,
      ),
      file501c3: new FormControl<string>(this._file501c3$.getValue()?.[0]?.id || null),
      hidden: new FormControl<boolean>(true),
    });

    this.subscriptions.push(
      this._merchantAppService.merchantApplicationResponse$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((application) => {
          this.merchantApp = cloneDeep(application);
          this.resetApplication();
        }),
    );

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessDocumentsForm.disable();
          } else {
            this.businessDocumentsForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onContinueClicked(accountId?: string, stayOnStep?: boolean): void {
    this.businessDocumentsForm.markAllAsTouched();
    if (this.businessDocumentsForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    for (const field in this.businessDocumentsForm.controls) {
      const control = this.businessDocumentsForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.subscriptions.push(
      this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
        if (hasTsysProvider) {
          this.hasTsysProvider = true;
          if (this.businessDocumentsForm.value.businessDocsCheck) {
            // Articles of Incorporation
            let paDetails = this.merchantApp.legal_entity.patriot_act_details;
            if (!paDetails) {
              paDetails = {} as PatriotActDetails;
            }
            paDetails.business_license = null;
            paDetails.articles_of_incorporation = {
              state: (this.businessDocumentsForm.value.articlesOfIncorpState
                ? [...SelectorTypes.statesMap].find(
                    ([key, val]) => key === this.businessDocumentsForm.value.articlesOfIncorpState,
                  )[0]
                : null) as PAArticlesOfIncorporation.StateEnum,
              issued_at: moment(this.businessDocumentsForm.value.articlesOfIncorpIssuedAt, 'MM-DD-YYYY').toISOString(),
            };
            this.merchantApp.legal_entity.patriot_act_details = paDetails;
          } else {
            // Business License
            let paDetails = this.merchantApp.legal_entity.patriot_act_details;
            if (!paDetails) {
              paDetails = {} as PatriotActDetails;
            }
            paDetails.articles_of_incorporation = null;
            paDetails.business_license = {
              state: (this.businessDocumentsForm.value.businessLicenseState
                ? [...SelectorTypes.statesMap].find(
                    ([key, val]) => key === this.businessDocumentsForm.value.businessLicenseState,
                  )[0]
                : null) as PABusinessLicense.StateEnum,
              issued_at: moment(this.businessDocumentsForm.value.businessLicenseIssuedAt, 'MM-DD-YYYY').toISOString(),
              expires_at: moment(this.businessDocumentsForm.value.businessLicenseExpiresAt, 'MM-DD-YYYY').toISOString(),
              name: this.merchantApp.legal_entity.legal_name,
            };
            this.merchantApp.legal_entity.patriot_act_details = paDetails;
          }
        } else {
          this.merchantApp.legal_entity.patriot_act_details = {} as PatriotActDetails;
          this.merchantApp.legal_entity.patriot_act_details.articles_of_incorporation = {
            state: PAArticlesOfIncorporation.StateEnum.AK,
            issued_at: moment('01-01-2000', 'MM-DD-YYYY').toISOString(),
          };
        }
      }),
    );
    this._merchantAppService.updateProviderData();

    this._merchantAppService.updateMerchantApplication(
      this.merchantApp,
      this.stepNumber + (stayOnStep ? 0 : 1),
      accountId,
    );
  }

  private isAppUnsaved(): boolean {
    return false;
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;
    this.isCharityOrReligiousOrg = ble?.is_501c3 && this.hasTsysProvider;

    if (ble?.patriot_act_details?.articles_of_incorporation) {
      this.businessDocumentsForm.patchValue({ businessDocsCheck: true });
      this._showBusinessLicense$.next(false);
      this._showArticlesOfIncorp$.next(true);
    } else if (ble?.patriot_act_details?.business_license) {
      this.businessDocumentsForm.patchValue({ businessDocsCheck: false });
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(true);
    }

    this.businessDocumentsForm.controls['articlesOfIncorpIssuedAt'].setValue(
      ble?.patriot_act_details?.articles_of_incorporation?.issued_at
        ? moment(ble?.patriot_act_details?.articles_of_incorporation?.issued_at).format('MM/DD/YYYY')
        : null,
    );
    const aoiState = ble?.patriot_act_details?.articles_of_incorporation?.state
      ? [...SelectorTypes.statesMap].find(
          ([key, val]) => key === ble?.patriot_act_details?.articles_of_incorporation?.state,
        )[0]
      : null;
    this.businessDocumentsForm.controls['articlesOfIncorpState'].setValue(aoiState);

    this.businessDocumentsForm.controls['businessLicenseIssuedAt'].setValue(
      ble?.patriot_act_details?.business_license?.issued_at
        ? moment(ble?.patriot_act_details?.business_license?.issued_at).format('MM/DD/YYYY')
        : null,
    );
    this.businessDocumentsForm.controls['businessLicenseExpiresAt'].setValue(
      ble?.patriot_act_details?.business_license?.expires_at
        ? moment(ble?.patriot_act_details?.business_license?.expires_at).format('MM/DD/YYYY')
        : null,
    );
    const blState = ble?.patriot_act_details?.business_license?.state
      ? [...SelectorTypes.statesMap].find(([key, val]) => key === ble?.patriot_act_details?.business_license?.state)[0]
      : null;
    this.businessDocumentsForm.controls['businessLicenseState'].setValue(blState);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  public readable(input: string): string {
    let pieces = input.toLowerCase().split('_');
    for (let i = 0; i < pieces.length; i++) {
      if (!(pieces[i] === 'and' || pieces[i] === 'of' || pieces[i] === 'to' || pieces[i] === 'to')) {
        pieces[i] = pieces[i].charAt(0).toUpperCase() + pieces[i].slice(1);
      }
    }
    return pieces.join(' ');
  }

  public onBusinessDocsChange(): void {
    const provideArticles = this.businessDocumentsForm.value.businessDocsCheck;
    if (provideArticles) {
      this._showArticlesOfIncorp$.next(true);
      this._showBusinessLicense$.next(false);
    }
    if (provideArticles === false) {
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(true);
    }
    if (provideArticles === null) {
      this._showArticlesOfIncorp$.next(false);
      this._showBusinessLicense$.next(false);
    }
  }

  fileDeleted(event: string): void {
    this._documentsService
      .updateDocument(this.account.id, this.merchantApp?.legal_entity?.charity_document?.document_id, {
        status: InternalDocument.StatusEnum.REQUESTED,
      })
      .subscribe((response) => {
        if (response) {
          this.merchantApp.legal_entity.charity_document.status = null;
          this.businessDocumentsForm.patchValue({ file501c3: null });
          this.onContinueClicked(this.account?.id, true);
        }
      });
  }

  public pendingFilesChange(numOfFiles: number): void {
    this.pendingFiles = numOfFiles;
  }

  fileUploaded(event: ModelFile): void {
    const params: SubmitDocumentRequestParams = {
      tilledAccount: this.account.id,
      id: this.merchantApp?.legal_entity?.charity_document?.document_id,
      documentSubmitRequestParams: {
        file_ids: [event.id],
      },
    };
    this._documentsService.submitDocument(params).subscribe((response) => {
      if (response) {
        this.businessDocumentsForm.patchValue({ file501c3: event.id });
        this.onContinueClicked(this.account?.id, true);
      }
    });
  }
}
