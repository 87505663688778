<div class="top-of-form"></div>
<merchant-app-card
  [title]="!forConsole ? 'Business Documents' : null"
  [description]="
    !forConsole
      ? 'Help us verify your business by answering a few questions about your business registration documents'
      : null
  "
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="details-step" class="w-full" [formGroup]="businessDocumentsForm">
    <!-- used for hidden element to define button width -->
    <tilled-input name="hidden" [merchantApp]="true" class="pointer-events-none invisible -mb-13 flex opacity-0">
    </tilled-input>
    <!-- Business Documents/Patriot Act Details-->
    <div class="items-left flex flex-col">
      <div class="flex w-full flex-col">
        <tilled-paragraph-p2 class="pt-2" [bold]="true">Select your business registration document</tilled-paragraph-p2>
        <mat-radio-group
          data-test-id="details-business-docs-check"
          class="flex flex-col pt-2"
          color="primary"
          formControlName="businessDocsCheck"
          (change)="onBusinessDocsChange()"
        >
          <mat-radio-button [value]="true">Articles of Incorporation</mat-radio-button>
          <mat-radio-button [value]="false">Business License</mat-radio-button>
          <!-- <mat-radio-button [value]="null">I don't have either of these documents</mat-radio-button> -->
        </mat-radio-group>

        <!-- Articles of Incorporation -->
        <div [ngClass]="{ hidden: !(showArticlesOfIncorp$ | async) }" class="pt-8">
          <div class="w-full">
            <tilled-paragraph-p2 [bold]="true">Details about your Articles of Incorporation</tilled-paragraph-p2>
          </div>
          <tilled-input
            [merchantApp]="true"
            data-test-id="details-aoi-issued-at"
            name="articlesOfIncorpIssuedAt"
            label="Issue date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('articlesOfIncorpIssuedAt').errors?.['mask']"
            errorMessage="Issue date must be a valid date."
          ></tilled-input>
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 secondary="true">State</tilled-label-l1>
          </div>
          <tilled-select
            [merchantApp]="true"
            [multiple]="false"
            [options]="stateCodeMap"
            [placeholder]="'Choose One'"
            [controlName]="businessDocumentsForm.get('articlesOfIncorpState')"
            classOverride="pb-6"
            [classOverride]="forConsole ? 'w-full' : ''"
            data-test-id="details-aoi-state"
          ></tilled-select>
        </div>

        <!-- Business License -->
        <div [ngClass]="{ hidden: !(showBusinessLicense$ | async) }" class="pt-8">
          <div class="w-full">
            <tilled-paragraph-p2 [bold]="true">Details about your Business License</tilled-paragraph-p2>
          </div>
          <div class="w-full pb-2.5 pt-4">
            <tilled-label-l1 secondary="true">State</tilled-label-l1>
          </div>
          <tilled-select
            [merchantApp]="true"
            [multiple]="false"
            [options]="stateCodeMap"
            [placeholder]="'Choose One'"
            [controlName]="businessDocumentsForm.get('businessLicenseState')"
            [classOverride]="forConsole ? 'w-full' : ''"
            data-test-id="details-business-license-state"
          ></tilled-select>
          <tilled-input
            [merchantApp]="true"
            data-test-id="details-business-license-issued-at"
            name="businessLicenseIssuedAt"
            label="Issue date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('businessLicenseIssuedAt').errors?.['mask']"
            errorMessage="Issue date must be a valid date."
          ></tilled-input>
          <tilled-input
            [merchantApp]="true"
            data-test-id="details-business-license-expires-at"
            name="businessLicenseExpiresAt"
            label="Expiry date"
            placeholder="MM/DD/YYYY"
            [sentenceCasePlaceholder]="false"
            tilledLabel="true"
            mask="M0/d0/0000"
            [dropMaskCharacters]="false"
            [leadZeroDateTime]="true"
            [errors]="businessDocumentsForm.get('businessLicenseExpiresAt').errors?.['mask']"
            errorMessage="Expiry date must be a valid date."
          ></tilled-input>
        </div>
      </div>
    </div>
    <!--  501(c)(3) upload -->
    <div class="items-left flex flex-col" *ngIf="isCharityOrReligiousOrg">
      <div class="flex w-full flex-col">
        <tilled-paragraph-p2 class="pt-12" [bold]="true"
          >501(c)(3) - Please upload a copy of your 501(c)(3) letter.</tilled-paragraph-p2
        >
        <div>
          <app-file-upload
            data-test-id="dark-logo-upload"
            class="-ml-1 flex flex-col pt-8"
            [isPartnerApp]="false"
            [isMerchantApp]="true"
            [purpose]="filePurpose"
            [allowedFileTypes]="fileTypes"
            [fileDescriptions]="description501c3"
            [existingFiles$]="file501c3$"
            [allowMultipleFiles]="false"
            [slim]="true"
            [fileCountLimit]="1"
            (fileDeleted)="fileDeleted($event)"
            (fileUploaded)="fileUploaded($event)"
            (pendingFilesChange)="pendingFilesChange($event)"
          ></app-file-upload>
        </div>
      </div>
    </div>
  </form>
</merchant-app-card>
