import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT, OUTBOUND_TRANSFERS_ROUTE } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { OutboundTransferStatusPipe } from 'app/core/pipes/outbound-transfer-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { OutboundTransfersAppService } from 'app/core/services/outbound-transfers.app.service';
import { PricingTemplateAppService } from 'app/core/services/pricing-template.app.service';
import { CancelOutboundTransferDialogComponent } from 'app/modules/outbound-transfers/cancel-form-dialog/cancel-outbound-transfer-dialog.component';
import { Observable, of } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import {
  GetOutboundTransferRequestParams,
  InternalOutboundTransfer,
  ListOutboundTransfersRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { PaymentsTypes } from '../../core/data/payments-types';
import { ActionListItem } from '../action-list/action-list.model';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';

@Component({
  selector: 'app-outbound-transfer-list',
  templateUrl: './outbound-transfer-list.component.html',
  styleUrls: ['./outbound-transfer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class OutboundTransferListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() isMerchant: boolean;
  @Input() initialQuery: string = null;
  @Input() query: string = null;
  @Input() queryMerchantId: string = null;
  @Input() initialStartDate: string = null;
  @Input() initialEndDate: string = null;
  @Input() startDate: string = null;
  @Input() endDate: string = null;
  @Input() status: InternalOutboundTransfer.StatusEnum = null;
  @Input() customerId: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() updateQueryCallback: (
    q?: string,
    startDate?: string,
    endDate?: string,
    queryMerchantId?: string,
    page?: number,
    size?: number,
  ) => void;

  public secondaryReasonText = 'Send an outbound transfer to see outbound transfer data.';
  public dialogRef: any;
  public updatedOutboundTransfer$: Observable<InternalOutboundTransfer>;
  public outboundTransfersViewModel$: Observable<OutboundTransferViewModel[]>;
  public outboundTransfersCount$: Observable<number>;
  private outboundTransfers$: Observable<InternalOutboundTransfer[]>;

  public canceledOutboundTransfer: InternalOutboundTransfer;
  public outboundTransferCanceled$: Observable<boolean>;

  public selectedActionList: ActionListItem[];
  public hideColumnKeys: OutboundTransferListViewModel[] = ['id'];
  public isLoading = true;
  public sortInfo = null;

  constructor(
    private _outboundTransfersService: OutboundTransfersAppService,
    private _outboundTransferStatusPipe: OutboundTransferStatusPipe,
    private _pricingTemplateAppService: PricingTemplateAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _matDialog: MatDialog,
    private _authService: AuthService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.outboundTransfers$ = this._outboundTransfersService.outboundTransfers$;
    this.outboundTransfers$
      .pipe(
        takeUntil(this._unsubscribeAll),
        tap((result) => {
          this.outboundTransfersViewModel$ = of(this.getViewModelsFromOutboundTransfers(result));
          if (this.updateQueryCallback) {
            this.updateQueryCallback(
              this.query,
              this.startDate,
              this.endDate,
              this.queryMerchantId,
              this.pageIndex,
              this.pageSize,
            );
          }
        }),
      )
      .subscribe();

    this.getOutboundTransfers(this.pageSize, this.pageIndex);
    if (this.isMerchant) {
      this.hideColumnKeys = ['merchant_name', 'currency'];
    }
    this.hideColumnKeys.push('id');

    this.outboundTransfersCount$ = this._outboundTransfersService.outboundTransfersCount$;

    this.updatedOutboundTransfer$ = this._outboundTransfersService.outboundTransfer$;
    this.updatedOutboundTransfer$.pipe(takeUntil(this._unsubscribeAll)).subscribe((updatedTransfer) => {
      this.outboundTransfersViewModel$ = this.outboundTransfersViewModel$.pipe(
        map((viewModels) =>
          viewModels.map((viewModel) => {
            if (viewModel.id !== updatedTransfer.id) {
              return viewModel;
            } else {
              return this.convertOutboundTransferToViewModel(updatedTransfer, this.isMerchant);
            }
          }),
        ),
      );
    });

    this.outboundTransferCanceled$ = this._outboundTransfersService.outboundTransferCanceled$;
    this.outboundTransferCanceled$
      .pipe(
        tap(() => {
          this.updateOutboundTransferFromCancel();
        }),
        takeUntil(this._unsubscribeAll),
      )
      .subscribe();
    if (!this.isMerchant) {
      this._pricingTemplateAppService.getPricingTemplates(this.accountId).subscribe((pricingTemplates) => {
        if (!pricingTemplates.find((pricingTemplate) => pricingTemplate.currency === 'cad')) {
          this.hideColumnKeys.push('currency');
        }
      });
    }
  }

  // onChange fires before onInit so we want to load the outbound transfers just once here on
  // page load.
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.query && !changes.query.firstChange) ||
      (changes.queryMerchantId && !changes.queryMerchantId.firstChange) ||
      (changes.startDate && !changes.startDate.firstChange) ||
      (changes.endDate && !changes.endDate.firstChange) ||
      (changes.accountId && !changes.accountId.firstChange)
    ) {
      this.getOutboundTransfers(this.pageSize, 0);
    }
  }

  private updateOutboundTransferFromCancel(): void {
    const params: GetOutboundTransferRequestParams = {
      tilledAccount: this.canceledOutboundTransfer.account_id,
      id: this.canceledOutboundTransfer.id,
    };
    this._outboundTransfersService.getOutboundTransfer(params);
  }

  getOutboundTransfers = (size: number, index: number): void => {
    this.pageSize = size;
    this.pageIndex = index;
    const params: ListOutboundTransfersRequestParams = {
      tilledAccount: this.queryMerchantId ?? this.accountId,
      createdAtGte: this.startDate ? new Date(this.startDate).toString() : null,
      createdAtLte: this.endDate ? new Date(this.endDate).toString() : null,
      status: this.status as any,
      includeConnectedAccounts: !this.isMerchant,
      offset: size * index,
      limit: size,
      //customerId: this.customerId,
      //q: this.query,
    };

    this._outboundTransfersService.getAllOutboundTransfers(params);

    if (this.query || this.queryMerchantId || this.startDate || this.endDate) {
      this.secondaryReasonText = 'Update the filters to see results.';
    } else {
      this.secondaryReasonText = 'Send an outbound transfer to see outbound transfer data.';
    }
  };

  convertOutboundTransferToViewModel(
    outboundTransfer: InternalOutboundTransfer,
    isMerchant: boolean,
  ): OutboundTransferViewModel {
    const temp: OutboundTransferViewModel = new OutboundTransferViewModel();
    const last2 = outboundTransfer.payment_method.ach_debit?.last2 ?? outboundTransfer.payment_method.eft_debit?.last2;
    temp.id = outboundTransfer.id;
    temp.created_at = this._dateFormatPipe.transform(outboundTransfer.created_at);
    temp.merchant_id = outboundTransfer.account_id;
    temp.merchant_name = outboundTransfer.account.name;
    temp.billingName = outboundTransfer.payment_method.billing_details?.name
      ? outboundTransfer.payment_method.billing_details?.name
      : '-';
    temp.last_2 = '**' + last2;
    temp.transaction_type = PaymentsTypes.PaymentMethodDisplayText.get(outboundTransfer.payment_method?.type);
    temp.currency = new UpperCasePipe().transform(outboundTransfer.currency);
    temp.amount = -outboundTransfer.amount;
    temp.status = outboundTransfer.status;
    temp.status_chip_config = this._outboundTransferStatusPipe.transform(outboundTransfer.status, true);
    temp.action =
      !this._authService.isScopeAble('outbound_transfers:write') ||
      outboundTransfer.status !== InternalOutboundTransfer.StatusEnum.PENDING
        ? []
        : [
            {
              name: 'Cancel outbound transfer',
              callback: (): void => {
                this.cancelOutboundTransfer(outboundTransfer);
              },
              disabled: false,
            },
          ];
    temp.action = temp.action.concat([
      {
        name: 'View outbound transfer details',
        callback: (): void => {
          this._router.navigate([`/${outboundTransfer.account_id}/${OUTBOUND_TRANSFERS_ROUTE}/${outboundTransfer.id}`]);
        },
        disabled: false,
      },
    ]);
    if (!isMerchant) {
      temp.action = temp.action.concat([
        {
          name: 'View merchant',
          callback: (): void => {
            this._router.navigate([`/merchants/${outboundTransfer.account_id}`]);
          },
          disabled: false,
        },
      ]);
    }

    return temp;
  }

  getViewModelsFromOutboundTransfers(outboundTransfers: InternalOutboundTransfer[]): OutboundTransferViewModel[] {
    const viewModels: OutboundTransferViewModel[] = [];
    if (!outboundTransfers || outboundTransfers.length === 0) {
      const temp: OutboundTransferViewModel = new OutboundTransferViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const outboundTransfer of outboundTransfers) {
      let viewModel = this.convertOutboundTransferToViewModel(outboundTransfer, this.isMerchant);

      viewModels.push(viewModel);
    }
    return viewModels;
  }

  rowClickedCallback = (data: OutboundTransferViewModel, event?: MouseEvent): void => {
    const url = `${data.merchant_id}/${OUTBOUND_TRANSFERS_ROUTE}/${data.id}`;

    // Open a new window (Shift) or tab (Ctrl/Option)
    if (event?.shiftKey || event?.ctrlKey || event?.metaKey) {
      window.open(url, event?.shiftKey ? '_blank' : undefined);
    } else {
      this._router.navigate([url]);
    }
  };

  public cancelOutboundTransfer = (outboundTransfer: InternalOutboundTransfer): void => {
    this.canceledOutboundTransfer = outboundTransfer;
    this.dialogRef = this._matDialog.open(CancelOutboundTransferDialogComponent, {
      panelClass: 'cancel-outbound-transfer-dialog',
      disableClose: true,
      data: {
        action: 'new',
        accountId: outboundTransfer.account_id,
        outboundTransfer: outboundTransfer,
      },
    });

    this.dialogRef.afterClosed().subscribe((canceled: boolean) => {});
  };
}

type OutboundTransferListViewModel = keyof OutboundTransferViewModel;

export class OutboundTransferViewModel {
  outboundTransfer: InternalOutboundTransfer;

  merchant_id: string;

  @Column({
    order: 0,
    name: 'Transaction Date',
    styling: 'min-width:210px',
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Merchant',
    styling: 'padding-right: 2ch; width: 20ch; max-width: 20ch;',
  })
  merchant_name: string;

  @Column({ order: 2, name: 'Payment Method', styling: 'min-width:100px;' })
  transaction_type: string;

  @Column({
    order: 3,
    name: 'Billing Name',
    styling: 'min-width:120px',
  })
  billingName: string;

  @Column({
    order: 4,
    name: 'Last 2',
    styling: 'min-width:80px;',
  })
  last_2: string;

  @Column({
    order: 5,
    name: 'Transaction Id',
    styling: 'min-width:225px;',
  })
  id: string;

  // displayed, but also needed to display currency of amount
  @Column({
    order: 6,
    name: 'Currency',
    styling: 'min-width:70px;',
  })
  currency: string;

  @Column({
    order: 7,
    name: 'Status',
    isChip: true,
    chipConfig: 'status_chip_config',
    styling: 'min-width:140px;',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 8,
    name: 'Amount',
    isCurrency: true,
    styling: 'min-width:100px;',
  })
  amount: number;

  @Column({
    order: 9,
    isActionList: true,
  })
  action: ActionListItem[];
}
