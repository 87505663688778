/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Refund } from './refund';
import { PlatformFee } from './platformFee';
import { BalanceTransaction } from './balanceTransaction';


export interface Charge { 
    /**
     * Amount (in the smallest currency unit) captured. Can be less than the `amount` on the PaymentIntent if a partial capture was made.
     */
    amount_captured: number;
    /**
     * Amount (in the smallest currency unit) refunded. Can be less than the `amount` on the PaymentIntent if a partial refund was made.
     */
    amount_refunded: number;
    /**
     * The balance transaction that describes the impact of this charge on your account balance (not including refunds or disputes). Note: Multiple balance transactions may be associated with this resource and you can fetch them all with `/balance-transactions?source_id={id}`.
     * @deprecated
     */
    balance_transaction?: BalanceTransaction;
    /**
     * If the charge was created without capturing, this Boolean represents whether it is still uncaptured or has since been captured.
     */
    captured: boolean;
    /**
     * Time at which the charge was captured.
     */
    captured_at?: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Time at which the charge failed.
     */
    failed_at?: string;
    /**
     * Error code explaining reason for charge failure.
     */
    failure_code?: Charge.FailureCodeEnum;
    /**
     * Message to user further explaining the reason for the charge failure.
     */
    failure_message?: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The ID of the PaymentIntent associated with this charge.
     */
    payment_intent_id: string;
    /**
     * The id of the PaymentMethod at the time of the transaction.
     */
    payment_method_id: string;
    /**
     * The platform fee (if any) for the charge.
     */
    platform_fee?: PlatformFee;
    /**
     * Whether the charge has been fully refunded. If the charge is only partially refunded, this attribute will be false.
     */
    refunded: boolean;
    /**
     * A list of refunds that have been applied to the charge.
     */
    refunds: Array<Refund>;
    /**
     * The status of the payment.
     */
    status: Charge.StatusEnum;
    /**
     * Time at which the object was updated.
     */
    updated_at: string;
}
export namespace Charge {
    export type FailureCodeEnum = 'account_closed' | 'account_frozen' | 'account_number_invalid' | 'authorization_revoked' | 'avs_check_failed' | 'bank_account_blocked' | 'call_issuer' | 'card_not_supported' | 'expired_card' | 'fraudulent' | 'generic_decline' | 'incorrect_cvc' | 'insufficient_funds' | 'invalid_expiry' | 'invalid_field' | 'invalid_number' | 'limit_exceeded' | 'no_account' | 'not_authorized' | 'not_permitted' | 'processing_error' | 'not_recognized' | 'pickup_card' | 'restricted_card' | 'risk_blocked' | 'service_not_allowed' | 'stop_payment' | 'try_again_later' | 'internal_error' | 'no_action_taken' | 'card_removed' | 'canceled' | 'device_is_busy';
    export const FailureCodeEnum = {
        ACCOUNT_CLOSED: 'account_closed' as FailureCodeEnum,
        ACCOUNT_FROZEN: 'account_frozen' as FailureCodeEnum,
        ACCOUNT_NUMBER_INVALID: 'account_number_invalid' as FailureCodeEnum,
        AUTHORIZATION_REVOKED: 'authorization_revoked' as FailureCodeEnum,
        AVS_CHECK_FAILED: 'avs_check_failed' as FailureCodeEnum,
        BANK_ACCOUNT_BLOCKED: 'bank_account_blocked' as FailureCodeEnum,
        CALL_ISSUER: 'call_issuer' as FailureCodeEnum,
        CARD_NOT_SUPPORTED: 'card_not_supported' as FailureCodeEnum,
        EXPIRED_CARD: 'expired_card' as FailureCodeEnum,
        FRAUDULENT: 'fraudulent' as FailureCodeEnum,
        GENERIC_DECLINE: 'generic_decline' as FailureCodeEnum,
        INCORRECT_CVC: 'incorrect_cvc' as FailureCodeEnum,
        INSUFFICIENT_FUNDS: 'insufficient_funds' as FailureCodeEnum,
        INVALID_EXPIRY: 'invalid_expiry' as FailureCodeEnum,
        INVALID_FIELD: 'invalid_field' as FailureCodeEnum,
        INVALID_NUMBER: 'invalid_number' as FailureCodeEnum,
        LIMIT_EXCEEDED: 'limit_exceeded' as FailureCodeEnum,
        NO_ACCOUNT: 'no_account' as FailureCodeEnum,
        NOT_AUTHORIZED: 'not_authorized' as FailureCodeEnum,
        NOT_PERMITTED: 'not_permitted' as FailureCodeEnum,
        PROCESSING_ERROR: 'processing_error' as FailureCodeEnum,
        NOT_RECOGNIZED: 'not_recognized' as FailureCodeEnum,
        PICKUP_CARD: 'pickup_card' as FailureCodeEnum,
        RESTRICTED_CARD: 'restricted_card' as FailureCodeEnum,
        RISK_BLOCKED: 'risk_blocked' as FailureCodeEnum,
        SERVICE_NOT_ALLOWED: 'service_not_allowed' as FailureCodeEnum,
        STOP_PAYMENT: 'stop_payment' as FailureCodeEnum,
        TRY_AGAIN_LATER: 'try_again_later' as FailureCodeEnum,
        INTERNAL_ERROR: 'internal_error' as FailureCodeEnum,
        NO_ACTION_TAKEN: 'no_action_taken' as FailureCodeEnum,
        CARD_REMOVED: 'card_removed' as FailureCodeEnum,
        CANCELED: 'canceled' as FailureCodeEnum,
        DEVICE_IS_BUSY: 'device_is_busy' as FailureCodeEnum
    };
    export type StatusEnum = 'succeeded' | 'pending' | 'failed';
    export const StatusEnum = {
        SUCCEEDED: 'succeeded' as StatusEnum,
        PENDING: 'pending' as StatusEnum,
        FAILED: 'failed' as StatusEnum
    };
}


